@tailwind base;
@tailwind components;
@tailwind utilities;

// body {
//   @apply bg-black ;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: #FFF;
//     -webkit-transition: background-color 5000s ease-in-out 0s;
//     box-shadow: inset 0 0 20px 20px transparent;
//     caret-color: #FFF;
// }

:root {
  --primary: #6b22be;
  --primaryLight: #f9f0ff;
  --primaryDark: #400086;
  --secondary: #00a5a8;
  --success: #52c41a;
  --processing: #1677ff;
  --error: #c83333;
  --background: #f2f2f2;
  --secondaryBackground: #ffffff;
  --transparent: transparent;
  --siderBg: #ffffff;
  --primaryText: #080808;
  --secondaryText: #393939;
  --quaternaryText: rgba(0, 0, 0, 0.25);
  --cardBackgroundColor: #fafafa;
  --borderColor: #d9d9d9;
  --secondaryBorderColor: #f0f0f0;
  --textDisabled: #cccccc;
  --conversationOutputBg: #0416172a;
  --conversationFileViewBg: #0a1b1c;
  --conversationInputBg: #fafafa;
  --hyperLinkColor: #9b59b6;
  --nodeCompleted: #ddf3e6;
  --nodeWaitingForApproval: #fff4b5;
  --nodeApproved: #c4d7ff;
  --bgError: #fff2f0;
  --borderError: #ffe8e6;
  --colorInfo: #1677ff;
  --colorInfoBg: #e6f4ff;
  --colorInfoBorder: #91caff;
  --colorSuccessBg: #f6ffed;
  --colorSuccessBorder: #b7eb8f;
  --colorWarning: #faad14;
  --colorWarningBg: #fffbe6;
  --colorWarningBorder: #ffe58f;
  --menuItemHoverBG: #d9f2f2;
  --menuItemSelectedBG: #b2e0e0;
  --chatInputBackground: #f2f2f2;
  --nodeBorderColor: #393939;
  --backgroundNode: #ffffff;
  --edgeColor: #CECECE;

  --nodeCompletedColor: #09A36B;
 --secondaryAccentColor: #DDF3E6;
 --warningAccentColor: #FFF4EB;

  // --primary: #00A5A8;
  // --secondary: #5502B4;

  --primary-75: #40bcbe;
  --primary-50: #80d2d4;
  --primary-25: #bfe8e9;
  --primary-10: #e6f6f7;
  --primary-200: #027e80;
  --primary-300: #045658;
  --primary-400: #062f30;
  --primary-500: #071718;
  --primary-light-bg: #f2fafa;

  --secondary-75: #40bcbe;
  --secondary-50: #80d2d4;
  --secondary-25: #d4bfec;
  --secondary-10: #eee6f8;
  --secondary-200: #420389;
  --secondary-300: #2e055e;
  --secondary-400: #1b0633;
  --secondary-500: #0f0719;
  --secondary-light-bg: #f6f2fb;

  --gradient-primary: linear-gradient(180deg, #00a5a8 0%, #09a36b 100%);
  --gradient-primary-stroke: linear-gradient(180deg, #80d2d4 0%, #bfe8e9 100%);
  --gradient-secondary: linear-gradient(180deg, #5502b4 0%, #8041c7 100%);
  --gradient-secondary-stroke: linear-gradient(
    180deg,
    #8c74ef 0%,
    #d8d0f9 100%
  );
  --gradient-gray: linear-gradient(180deg, #cecece 0%, #e7e7e7 100%);

  --black: #080808;
  --grey-95: #151515;
  --grey-90: #212121;
  --grey-80: #393939;
  --grey-70: #535353;
  --grey-60: #6b6b6b;
  --grey-50: #848484;
  --grey-40: #9c9c9c;
  --grey-30: #b5b5b5;
  --grey-20: #cecece;
  --grey-10: #e7e7e7;
  --grey-5: #f2f2f2;
  --white: #ffffff;
  --input-bg: #ffffff;

  --shadow-xs: 0px 1px 2px 0px #f2f2f2;
  --shadow-sm: 0px 1px 2px 0px #f2f2f2, 0px 1px 3px 0px #e7e7e7;
  --shadow-md: 0px 2px 4px -2px #f2f2f2, 0px 4px 8px -2px #e7e7e7;
  --shadow-lg: 0px 4px 6px -2px #f2f2f2, 0px 12px 16px -4px #f2f2f2;
  --shadow-xl: 0px 8px 8px -4px #f2f2f2, 0px 20px 24px -4px #f2f2f2;
  --shadow-2xl: 0px 24px 48px -12px #cecece;
  --shadow-3xl: 0px 32px 64px -12px #cecece;
}

body.dark-mode {
  --primary: #b583ff;
  --primaryLight: #362046;
  --primaryDark: #6f00c6;
  --secondary: #17b3b6;
  --success: #4caf50;
  --processing: #62b6ff;
  --error: #ff6b6b;
  --background: #121212;
  --secondaryBackground: #0d0d0d;
  --transparent: transparent;
  --siderBg: #080808;
  --primaryText: #ffffff;
  --secondaryText: #b3b3b3;
  --quaternaryText: #393939;
  --cardBackgroundColor: #1e1e1e;
  --borderColor: #262626;
  --secondaryBorderColor: #3a3a3a;
  --textDisabled: #666666;
  --conversationOutputBg: #0a1b1c;
  --conversationFileViewBg: #0a1b1c;
  --conversationInputBg: #1e1e1e;
  --hyperLinkColor: #9b59b6;
  --nodeCompleted: #2a3b2f;
  --nodeWaitingForApproval: #554c2a;
  --nodeApproved: #2a3b55;
  --bgError: #4d3836;
  --borderError: #3d2e2d;
  --colorInfo: #5ecaff;
  --colorInfoBg: #002b48;
  --colorInfoBorder: #17406d;
  --colorSuccess: #52c41a;
  --colorSuccessBg: #132a13;
  --colorSuccessBorder: #1c561e;
  --colorWarning: #faad14;
  --colorWarningBg: #402c00;
  --colorWarningBorder: #614700;
  --menuItemHoverBG: #041617;
  --menuItemSelectedBG: #0b3232;
  --chatInputBackground: #1e1e1e;
  --nodeBorderColor: #4A4A4A;
  --backgroundNode: #080808;
  --edgeColor: #4A4A4A;
  --secondaryAccentColor: #1A3A2A;
  --nodeCompletedColor: #166848;
  --warningAccentColor: #5C3D31;


  // --primary: #32EBEE;
  // --secondary: #9D47FF;

  --primary-75: #25b0b2;
  --primary-50: #197577;
  --primary-25: #0c3b3b;
  --primary-10: #051718;
  --primary-200: #65f0f2;
  --primary-300: #99f5f7;
  --primary-400: #cbfafa;
  --primary-500: #ebfdfe;
  --primary-light-bg: #020909;

  --secondary-75: #7837c1;
  --secondary-50: #522783;
  --secondary-25: #2d1846;
  --secondary-10: #170e20;
  --secondary-200: #b675ff;
  --secondary-300: #cea3ff;
  --secondary-400: #e6d1ff;
  --secondary-500: #f6edff;
  --secondary-light-bg: #100c15;

  --gradient-primary: linear-gradient(180deg, #32ebee 0%, #3cb88b 100%);
  --gradient-primary-stroke: linear-gradient(
    180deg,
    #32ebee 0%,
    rgba(50, 235, 238, 0) 100%
  );
  --gradient-secondary: linear-gradient(180deg, #9d47ff 0%, #b675ff 100%);
  --gradient-secondary-stroke: linear-gradient(
    180deg,
    #9d47ff 0%,
    rgba(157, 71, 255, 0) 100%
  );
  --gradient-gray: linear-gradient(180deg, #cecece 0%, #e7e7e7 100%);

  --black: #000000;
  --grey-95: #151515;
  --grey-90: #1a1a1a;
  --grey-80: #333333;
  --grey-70: #4d4d4d;
  --grey-60: #666666;
  --grey-50: #808080;
  --grey-40: #999999;
  --grey-30: #b3b3b3;
  --grey-20: #cccccc;
  --grey-10: #e6e6e6;
  --grey-5: #f2f2f2;
  --white: #ffffff;
  --input-bg: #1e1e1e;

  --shadow-xs: 0px 1px 2px 0px #f2f2f2;
  --shadow-sm: 0px 1px 2px 0px #f2f2f2, 0px 1px 3px 0px #e7e7e7;
  --shadow-md: 0px 2px 4px -2px #f2f2f2, 0px 4px 8px -2px #e7e7e7;
  --shadow-lg: 0px 4px 6px -2px #f2f2f2, 0px 12px 16px -4px #f2f2f2;
  --shadow-xl: 0px 8px 8px -4px #f2f2f2, 0px 20px 24px -4px #f2f2f2;
  --shadow-2xl: 0px 24px 48px -12px #cecece;
  --shadow-3xl: 0px 32px 64px -12px #cecece;
}

.ant-typography {
  font-family: var(--font-inter-tight), sans-serif !important;
}

body,
.ant,
.ant-tabs {
  font-family: var(--font-inter-tight), sans-serif !important;
}

.ant-typography,
.ant-btn,
.ant-input {
  font-family: var(--font-inter-tight), sans-serif !important;
}

.unsupported-field {
  display: none !important;
}
.reactflow-link-hide {
  .react-flow__attribution {
    display: none;
  }
}
.inter-tight-100 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.inter-tight-200 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.inter-tight-300 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.inter-tight-400 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.inter-tight-500 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.inter-tight-600 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.inter-tight-700 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.inter-tight-800 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.inter-tight-900 {
  font-family: var(--font-inter-tight), sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.ant-checkbox-inner {
  border: 2px solid #005b5c;
}

.ant-tooltip-inner {
  text-align: center !important;
}

.autobots-sider-menu .ant-menu-item {
  height: 48px !important;
  padding: 16px !important;
}
.list-disc {
  * {
    color: white;
  }
}
.ant-collapse-header {
  align-items: center !important;
}

.ant-btn {
  transition: transform 0.2s ease;
}

.ant-btn-primary:hover {
  transform: translateY(-2px);
}

.ant-btn-default:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ant-modal-close-x {
  color: #c83333;
}

.ant-modal-mask {
  backdrop-filter: blur(50px) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.custom-tab {
  .ant-tabs-nav {
    margin: 0 !important;
    font-weight: 400 !important;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6b22be !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #7b42bd !important;
}
.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  background-color: #70707070 !important;
  background: #70707070 !important;
  color: rgba(0, 0, 0, 0.644) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-btn-default:disabled,
.ant-btn-primary.ant-btn-disabled {
  background-color: #70707070 !important;
  background: #70707070 !important;
  color: rgba(0, 0, 0, 0.644) !important;
  border-color: #ffffff !important;
  box-shadow: none !important;
}

.custom-input-app-link {
  height: 41px;
  transition: border-color 0.3s;
}

.custom-input-app-link:hover {
  border-color: #6b22be !important;
}

.custom-input-app-link:focus {
  border-color: #6b22be !important;
  box-shadow: 0 0 0 2px rgba(155, 5, 255, 0.06) !important;
}

.ant-card-hoverable:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.language-plaintext {
  white-space: normal;
}

.custom-dashboard-row td {
  border-bottom: none !important;
  padding: 10px !important;
}

.custom-dashboard-row th {
  border-bottom: none !important;
  padding: 10px !important;
}

::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background: var(--background) !important;
  border-radius: 16px !important;
  margin: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: var(--menuItemSelectedBG) !important;
  border: 3px solid transparent !important;
  background-clip: content-box !important;
  border-radius: 16px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--menuItemSelectedBG) !important;
  border: 3px solid transparent !important;
  background-clip: content-box !important;
  border-radius: 16px !important;
}

// body.dark-mode {
//   scrollbar-color: rgba(0, 0, 0, 0.25) rgba(245, 245, 245, 0.13); /* thumb color, track color */
// }

//   body.dark-mode .menu-scroll-container {
//     // scrollbar-color: auto !important; /* Reset scrollbar-color to default so WebKit styles work */
//     scrollbar-width: thin !important;
// }

body.dark-mode .ant-select-fine-tune :where(.ant-select-selection-placeholder) {
  color: #b3b3b3; /* Change this to your desired color */
  opacity: 1; /* Ensures the color appears as intended */
}

.ant-pagination-item-active {
  a {
    color: var(--primaryText) !important;
  }
  border-color: transparent !important;
}
.ant-pagination-item {
  a {
    color: var(--secondaryText) !important;
  }
}

.custom-form {
  .ant-input {
    border: 1px solid var(--borderColor, #e7e7e7);
    box-shadow: 5px 5px 16px 0px var(--conversationInputBg) !important;
    padding: 12px !important;
    font-size: 14px !important;
    background-color: var(--input-bg);
    gap: 14px;
  }
  
  .ant-input-number {
    box-shadow: 5px 5px 16px 0px var(--conversationInputBg) !important;
    background-color: var(--input-bg);
    gap: 14px;
    width: 100%;
    // Remove padding from outer container
    padding: 0 !important;
  
    .ant-input-number-input {
      padding: 12px !important; // Apply padding only to the actual input
      font-size: 14px !important;
    }
  }

  .ant-select-single {
    height: 48px;
  }
  .ant-select-selector {
    box-shadow: 5px 5px 16px 0px var(--conversationInputBg) !important;
    background-color: var(--input-bg) !important;
  }
  .rjsf {
    max-width: 600px;
  }
}


  .custom-node-container {
    width: 220px;
    background-color: var(--backgroundNode);
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--nodeBorderColor)
  }


  .react-flow__handle{
    background-color: var(--edgeColor) !important;
    border: none !important;
  } 

  .react-flow__handle:hover {
    background-color: var(--edgeColor) !important;
    border: none !important;
    scale: 1.1;
  } 

  .react-flow__node-input {
    color: var(--primaryText) !important;
    background-color: var(--backgroundNode) !important;
    border-radius: 8px !important;
    border: 1px solid var(--nodeBorderColor);
    width: 110px !important;
    font-weight: 600 !important;
  }

  .ant-table-container::before,
.ant-table-container::after {
  box-shadow: none !important; /* Removes both shadows completely */
}

.ant-table-wrapper .ant-table .ant-table-header{
  border-radius: 8px !important;
}

.ant-table-thead > tr > th {
  border-right: none !important;
  border-bottom: none !important;
}

.ant-table-thead > tr > th::before {
  display: none !important;
}


.ant-table-tbody > tr:hover > td {
  background: transparent !important; /* Override Ant Design's hover background */
}